<template>
  <!-- v-if="$store.state.order.products && $store.state.order.products[0]" -->
  <v-footer app id="footer" :elevation="18">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <v-btn
            :disabled="isPaymentButtonDisabled"
            :loading="loading"
            @click="submitPayment"
            color="info"
            class="px-5"
            block
            depressed
            x-large
            rounded
          >
            PAGAR {{ currencySymbol() }}{{ formatPrice(paymentAmount, 2) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { formatPrice, currencySymbol } from "@/utils/price.js";
import { getPaymentCheckoutUrlOrder } from "@/plugins/axios";
import thankYouCommon from "@/mixins/firebase/thankYouCommon";

export default {
  mixins: [thankYouCommon],
  provide: {
    heading: { align: "center" },
  },

  data() {
    return {
      loading: false,
    };
  },

  props: {
    uuid: {
      type: String,
    },
    paymentAmount: {
      type: Number,
    },
    hasAnyProductSelected: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTipLeftAlone: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  mounted() {
    // const uuid = window.localStorage.getItem("multipaymentUuid");
    // if (uuid) {
    //   this.$store.dispatch("order/updateFailurePayment", {
    //     uuid,
    //   });
    // }
  },
  computed: {
    isPaymentButtonDisabled() {
      return (
        this.paymentAmount <= 0 ||
        !this.hasAnyProductSelected ||
        this.isTipLeftAlone
      );
    },
  },
  methods: {
    formatPrice,
    currencySymbol,
    redirectToPaymentCheckout(url) {
      window.location.href = url;
    },
    async submitPayment() {
      this.loading = true;

      const payload = {
        table: this.$route.query.table,
        shopName: this.$store.state.menu.profile.shopName,
        shopCode: this.$route.query.commerceId,
        total: this.paymentAmount,
        uuid: this.uuid,
        isPartial: true,
      };

      if (!this.getOrderUuid) {
        await getPaymentCheckoutUrlOrder(payload)
          .then((response) => {
            if (response.data?.responseCode === 0) {
              this.redirectToPaymentCheckout(response.data?.paymentUrl);
            } else {
              this.$store.commit("snackbar/OPEN_SNACKBAR", {
                msg: response.data?.responseMessage,
                success: false,
              });
            }
          })
          .catch(() => {
            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              msg: "Hubo un error, intenta de nuevo.",
              success: false,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          msg: "No se puede hacer un pago parcial, mientras haya un pago total en progreso. ",
          success: false,
        });
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
#footer
  background-position: center bottom
  a
    text-decoration: none

#cart-button-text
  letter-spacing: 0.06rem !important
</style>
